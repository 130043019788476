<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section section-page-header">
      <div class="container m-container-only-s">
        <h1>{{ $t("crew.main") }}</h1>
        <h3>
          {{
            isset(() => flightDetails.legs[0].origin)
              ? mutateCode(flightDetails.legs[0].origin).name
              : ""
          }}
          ({{ flightDetails.origin }}) -

          {{
            isset(() => flightDetails.legs[0].destination)
              ? mutateCode(flightDetails.legs[0].destination).name
              : ""
          }}

          ({{ flightDetails.destination }}) ({{ flight.flightNumber }})
        </h3>
      </div>
    </section>
    <section class="section section-default section-crew">
      <div class="container m-container-only-s">
        <ul class="crew">
          <li :key="index" v-for="(item, index) in crewEmployers">
            <router-link to="">
              <h4>
                {{ capitalize(mutateKeyLang(item, "firstName").toLowerCase()) }}
              </h4>
              <figure>
                <img
                  :src="
                    replaceCoverImage(item.personImage) ||
                      require('@/assets/i/crew-empty-male.svg')
                  "
                  alt=""
                />
              </figure>
              <p>{{ mutationCrew(item.position) }}</p>
              <a
                href=""
                @click.prevent="showModalAction(item)"
                class="more nowrap"
                >{{ $t("crew.details") }}</a
              >
            </router-link>
          </li>
        </ul>
      </div>
    </section>
    <section class="section section-default section-crew">
      <div class="container m-container-only-s">
        <ul class="crew">
          <li :key="index" v-for="(item, index) in crewEmployersBP">
            <router-link to="">
              <h4>
                {{ capitalize(mutateKeyLang(item, "firstName").toLowerCase()) }}
              </h4>
              <figure>
                <img
                  :src="
                    replaceCoverImage(item.personImage) ||
                      require('@/assets/i/crew-empty-male.svg')
                  "
                  alt=""
                />
              </figure>
              <p>{{ mutationCrew(item.position) }}</p>
              <a
                href=""
                @click.prevent="showModalAction(item)"
                class="more nowrap"
                >{{ $t("crew.details") }}</a
              >
            </router-link>
          </li>
        </ul>
      </div>
    </section>
    <transition name="fade">
      <div class="modal crew-modal modal-open" v-if="showModal">
        <div class="table">
          <div class="table-cell">
            <div class="modal-container">
              <a href="" class="close" @click.prevent="closeModal"></a>

              <div class="modal-content">
                <div class="row">
                  <div class="col col-12 col-s-24">
                    <h4>
                      {{
                        capitalize(
                          mutateKeyLang(itemModal, "firstName").toLowerCase()
                        )
                      }}
                    </h4>

                    <figure>
                      <img
                        :src="
                          replaceCoverImage(itemModal.personImage) ||
                            require('@/assets/img/crew.jpg')
                        "
                        alt=""
                      />
                    </figure>
                    <!--                    <router-link class="btn" to="">{{-->
                    <!--                      $t("crew.feedback")-->
                    <!--                    }}</router-link>-->
                  </div>
                  <div class="col col-12 col-s-24">
                    {{ mutateKeyLang(itemModal, "description") }}
                    <dl>
                      <dt>{{ $t("crew.position") }}:</dt>
                      <dd>{{ mutationCrew(itemModal.position) }}</dd>

                      <dt>{{ $t("crew.languages") }}:</dt>
                      <dd>{{ getLocaleLanguages(itemModal.languages) }}</dd>

                      <!--                                          <dt>профессиональные достижения:</dt>-->
                      <!--                                          <dd>Работает без отпуска уже 5 лет.</dd>-->

                      <!--                                          <dt>Личные достижения:</dt>-->
                      <!--                                          <dd>Вскопала летом 1,5 га чернозема</dd>-->

                      <!--                                          <dt>хобби:</dt>-->
                      <!--                                          <dd>художественная лепка куличиков на скорость</dd>-->

                      <!--                                          <dt>цитата:</dt>-->
                      <!--                                          <dd>«Я помню чудное многвенье...»</dd>-->
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </main>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import cover from "@/mixins/cover";
import flight from "@/mixins/flight";

export default {
  name: "Crew",
  title() {
    return this.$t("pages.crew");
  },
  mixins: [cover, flight],
  computed: {
    crewEmployers() {
      // return [
      //   {
      //     Description_EN: "",
      //     Description_RU: "",
      //     EmployeeId: 102676,
      //     FirstName_EN: "Ivan",
      //     FirstName_RU: "Иван",
      //     Languages: ["RU", "EN"],
      //     PersonImage: require("@/assets/crew/102676.png"),
      //     Position: "PIC"
      //   },
      //   {
      //     Description_EN: "",
      //     Description_RU: "",
      //     EmployeeId: 110522,
      //     FirstName_EN: "Sergey",
      //     FirstName_RU: "Сергей",
      //     Languages: ["RU", "EN"],
      //     PersonImage: require("@/assets/crew/110522.png"),
      //     Position: "CO"
      //   },
      //   {
      //     Description_EN: "",
      //     Description_RU: "",
      //     EmployeeId: 119698,
      //     FirstName_EN: "Andrey",
      //     FirstName_RU: "Андрей",
      //     Languages: ["RU", "EN"],
      //     PersonImage: require("@/assets/crew/119698.png"),
      //     Position: "PI"
      //   }
      // ];

      return this.flight.crewEmployers
        .filter(
          item =>
            (item.position === "PIC" ||
              item.position === "PI" ||
              item.position === "CO" ||
              item.position === "FO") &&
            item.employeeId !== 123456
        )
        .sort((a, b) => a.order - b.order);
    },
    crewEmployersBP() {
      // return [
      //   {
      //     Description_EN: "",
      //     Description_RU: "",
      //     EmployeeId: 99975,
      //     FirstName_EN: "Alice",
      //     FirstName_RU: "Алиса",
      //     Languages: ["RU", "EN"],
      //     PersonImage: require("@/assets/crew/99975.png"),
      //     Position: "CCC"
      //   },
      //   {
      //     Description_EN: "",
      //     Description_RU: "",
      //     EmployeeId: 102738,
      //     FirstName_EN: "Natasha",
      //     FirstName_RU: "Наташа",
      //     Languages: ["RU", "EN"],
      //     PersonImage: require("@/assets/crew/102738.png"),
      //     Position: "CC"
      //   },
      //   {
      //     Description_EN: "",
      //     Description_RU: "",
      //     EmployeeId: 104162,
      //     FirstName_EN: "Julia",
      //     FirstName_RU: "Юлия",
      //     Languages: ["RU", "EN"],
      //     PersonImage: require("@/assets/crew/104162.png"),
      //     Position: "CC"
      //   },
      //   {
      //     Description_EN: "",
      //     Description_RU: "",
      //     EmployeeId: 105131,
      //     FirstName_EN: "Elena",
      //     FirstName_RU: "Елена",
      //     Languages: ["RU", "EN"],
      //     PersonImage: require("@/assets/crew/105131.png"),
      //     Position: "CI"
      //   }
      // ];
      return this.flight.crewEmployers
        .filter(
          item =>
            (item.position === "CCC" ||
              item.position === "CC" ||
              item.position === "CI") &&
            item.employeeId !== 123456
        )
        .sort((a, b) => a.order - b.order);
    },
    ...mapState("flight", {
      flightDetailsLoading: state => state.flightDetailsLoading,
      flightLoading: state => state.flightLoading,
      flightDetails: state => state.flightDetails
      // flight: state => state.flight
    }),
    ...mapState({
      locale: state => state.locale
    }),
    ...mapGetters("flight", {
      flight: "SORT_POSITION"
    })
  },
  data: () => {
    return {
      itemModal: {},
      showModal: false
    };
  },
  beforeRouteLeave(to, from, next) {
    document.documentElement.classList.remove("modal-opened");
    next();
  },

  methods: {
    showModalAction(item) {
      this.itemModal = item;
      this.showModal = true;
      document.documentElement.classList.add("modal-opened");
    },
    capitalize(s) {
      return s[0].toUpperCase() + s.slice(1);
    },
    isset(accessor) {
      try {
        return typeof accessor() !== "undefined";
      } catch (e) {
        return false;
      }
    },
    mutationCrew(crew) {
      let obj = {
        ru: {
          PIC: "Командир воздушного судна",
          CO: "Второй пилот",
          PI: "Пилот инструктор",
          FO: "Второй пилот",
          CCC: "Старший бортпроводник",
          CC: "Бортпроводник",
          CI: "Бортпроводник – инструктор"
        },
        en: {
          PIC: "Captain",
          CO: "First Officer",
          PI: "Cabin instructor",
          FO: "First Officer",
          CCC: "Cabin chief ",
          CC: "Cabin attendant",
          CI: "Cabin instructor"
        }
      };
      return obj[this.$i18n.locale][crew] || "NotFound";
    },
    getLocaleLanguages(languages) {
      const langMap = languages.map(item => {
        return this.$t("codes." + item.toLowerCase() + ".nativeName");
      });
      return langMap.join(", ");
    },
    closeModal() {
      this.itemModal = {};
      document.documentElement.classList.remove("modal-opened");
      this.showModal = false;
    },
    ...mapActions("content", {
      actionItem: "item"
    }),
    ...mapActions({
      setLocale: "SET_LOCALE"
    }),
    ...mapMutations("content", {
      setEntityContent: "SET_ENTITY"
    })
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
